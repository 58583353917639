

















































import { Component, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import DutyTableComponent from '@/pages/dashboard/duty-management/duty-table.vue';
import DutyManagementService from '@/service/duty-management';
import moment from 'moment';

@Component({
    components: {
        'duty-table': DutyTableComponent
    }
})
export default class DutyCalendarComponent extends BaseComponent {
    // 值班表类型： my || all
    type: string = 'all';
    // 展示方式： calendar || people
    showType: string = 'calendar';
    showTypeVisible: boolean = false;
    dutyList: Array<any> = [];
    dutyCalendarData: Array<any> = [];
    dutyTableData: Array<any> = [];
    // month || year
    selectedCalendarMode: string = 'month';
    selectedMonth: string = moment().format('YYYY-MM');
    selectedDutyId: string = '';
    detailId: string = undefined;
    moment = moment;

    @Watch('$route.params')
    routerChange(params): void {
        this.getDutyList();
        this.getDutyData();
    }

    created() {
        this.getDutyList();
        this.getDutyData();
    }

    getDutyList() {
        this.type = this.$route.params.type;
        if (this.type === 'all') {
            this.showType = 'calendar';
            this.dutyList.push({ label: '全部', value: '' });
            DutyManagementService.query(null, 1, 999).then(res => {
                _.forEach(res.items, item => {
                    this.dutyList.push({
                        label: item.departmentName,
                        value: item.id
                    });
                });
            });
        } else {
            this.dutyList = [];
            this.showType = 'calendar';
        }
    }

    getDutyData() {
        this.startFullScreenLoading();
        if (this.type === 'all') {
            DutyManagementService.getDutyCalendar(this.selectedMonth, this.selectedDutyId).then(res => {
                this.dutyCalendarData = res;
                this.getDutyTableData();
            }).catch((err) => {
                this.showMessageError(err);
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        } else {
            DutyManagementService.getMyDutyCalendar(this.selectedMonth).then(res => {
                this.dutyCalendarData = res;
                this.dutyTableData = [];
            }).catch((err) => {
                this.showMessageError(err);
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        }
    }

    getDutyTableData() {
        this.dutyTableData = [];

        _.forEach(this.dutyCalendarData, item => {
            _.forEach(item.shiftDutyRosters, userItem => {
                console.log(userItem);
                const matchList = _.filter(this.dutyTableData, tableItem => { return tableItem.userName === userItem.userName; });
                if (matchList && matchList.length > 0) {
                    matchList[0].shiftDates.push({
                        shiftName: userItem.shiftName || '休息',
                        month: this.selectedMonth,
                        day: item.day
                    });
                } else {
                    this.dutyTableData.push({
                        userName: userItem.userName,
                        shiftDates: [
                            {
                                shiftName: userItem.shiftName || '休息',
                                month: this.selectedMonth,
                                day: item.day
                            }
                        ]
                    });
                }
            });
        });

        console.log(this.dutyTableData);
    }

    getListData(value) {
        let listData = [];
        const valMonth = moment(value).format('MM');
        const valDay = moment(value).date().toString();

        if (valMonth === moment(this.selectedMonth).format('MM')) {
            const matchList = _.filter(this.dutyCalendarData, item => { return item.day === valDay; });
            if (matchList && matchList.length > 0) {
                const match = matchList[0];
                if (this.type === 'all') {
                    listData = _.forEach(match.shiftDutyRosters, item => {
                        item.content = item.shiftName ? item.userName + '（' + item.shiftName + '）' : item.userName + '（休息）';
                    });
                } else {
                    listData = _.forEach(match.shifts, item => {
                        if (moment(item.startTime).format('YYYY-MM-DD') !== moment(item.endTime).format('YYYY-MM-DD')) {
                            item.timeSpan = moment(item.startTime).format('HH:mm') + ' - 次日' + moment(item.endTime).format('HH:mm');
                        } else {
                            item.timeSpan = moment(item.startTime).format('HH:mm') + ' - ' + moment(item.endTime).format('HH:mm');
                        }
                        item.content = item.shiftName ? item.shiftName + '（' + item.timeSpan + '）' : '休息' + '（休息）';
                    });
                }
            }
        }
        return listData || [];
    }

    switchShowType(type) {
        if (type !== this.showType) {
            this.showType = type;
        }
        this.showTypeVisible = false;
    }

    selectShowType() {
        this.showTypeVisible = true;
    }

    exportDuty() {
        // 导出报表 暂时不做
    }

    calendarPanelChanged(value, mode) {
        this.selectedCalendarMode = mode;
    }

    calendarMonthChanged(value) {
        const formatVal = moment(value).format('YYYY-MM');
        if (formatVal !== this.selectedMonth) {
            this.selectedMonth = formatVal;
            this.getDutyData();
        }
    }

    calendarCellSelected(value) {
        const formatVal = moment(value).format('YYYY-MM');
        if (this.selectedCalendarMode === 'year') {
            this.selectedCalendarMode = 'month';
            if (formatVal !== this.selectedMonth) {
                this.selectedMonth = formatVal;
                this.getDutyData();
            }
        }
    }

    dutyChanged() {
        this.getDutyData();
    }
}
